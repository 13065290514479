import React from "react";
import styled from "styled-components";
import { generateMedia } from "styled-media-query";

export default function TwoPartBanner({
  children,
  title,
  subtitles,
  alignment,
}) {
  return (
    <BannerContainer>
      <div className="img-container animate-box fadeInLeft animated-fast">
        {children}
      </div>
      <section className="txt-container animate-box pulse animated-fast">
        <h2 className="">{title}</h2>
        <div style={{ textAlign: alignment ? alignment : "center" }}>
          {subtitles.map((subtitle, idx) => {
            return (
              <React.Fragment>
                <p key={`banner-subtitle-${idx}`}>{subtitle}</p>
                <br />
              </React.Fragment>
            );
          })}
        </div>
      </section>
    </BannerContainer>
  );
}

const customMedia = generateMedia({
  smPhone: "500px",
  phone: "760px",
  lgPhone: "900px",
});

const BannerContainer = styled.div`
  padding: 3%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: max-content;
  box-shadow: inset 0 -21px 36px -3px #f0f0f0;
  ${customMedia.lessThan("lgPhone")`
    padding: 5%;
  `}

  ${customMedia.lessThan("phone")`
    grid-template-columns: 1fr;
    .img-container > img {
      max-height: 20rem;
    }
  `}
  ${customMedia.lessThan("smPhone")`
  .img-container > img {
    max-height: 16rem;
  }
  `}

  .img-container {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
  section.txt-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 1.75rem;
    h2 {
      font-size: 3.3em;
      line-height: 1em;
      padding-bottom: 1em;
      text-transform: capitalize;
      ${customMedia.lessThan("lgPhone")`
        font-size: 4rem;
      `} ${customMedia.lessThan("phone")`
        font-size: 3rem;
      `};
    }
    p {
      font-size: 20px;
      line-height: 1.5;
    }
  }
`;
