import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { contactInfoReducer, addToNewsletterReducer } from './reducers/emailReducers';
import { mapDirectionsReducer } from './reducers/mapReducers';

const initialState = {
  contactInfo: {}
};

const reducer = combineReducers({
  contactInfo: contactInfoReducer,
  newsLetterInfo: addToNewsletterReducer,
  directionsData: mapDirectionsReducer
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
