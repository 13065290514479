import React from 'react'
import styled from 'styled-components';
import img from '../../assets/images/accreditation-emblem.png';
import img2 from '../../assets/images/photo.jpg';

export default function TabbedServicesPanelTwo() {
  return (
    <TabPanelContainer>
      <div className="tab-content">
        <div className="tab-left-content">
          <img src={img} alt="accreditation emblem" />
        </div>
        <div className="tab-right-content">
          <div className="image-container"> 
            <img src={img2} alt="Dr. Nas"/>
          </div>
          <p>
            Dr. Nas is a an esteemed member of the College of Veterinarians of Ontario and Canadian Veterinary Medical Association.
            Here at Clairfields Mobile Veterinary Clinic we pride ourselves for our <span style={{textDecoration: "underline", fontWeight: "700"}}>professionalism</span>, <span style={{textDecoration: "underline", fontWeight: "700"}}>integrity</span>, and <span style={{textDecoration: "underline", fontWeight: "700"}}>commitment to our customers</span>.
          </p>
        </div>
      </div>
    </TabPanelContainer>
  )
}

/* 
 We develop relationships that make a positive difference in our customers' lives.
*/

const TabPanelContainer = styled.div`
  background-image:  linear-gradient(to right, #F0F0A2 0%, #F0F0A2 50%, #FAEDA2 50%);;

  .tab-content {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    padding: 2rem;
    text-align: center;
    margin-top: 0.25rem;
    
    .tab-left-content{
      grid-column: 1;
      justify-self: center;
        img{
          width: 80%;
        }
      }
    }
    .tab-right-content{
      .image-container {
        background-color: rgba(150,176,155,0.6);
        position: relative;
        margin: auto;
        margin-bottom: 5rem;
        width: 30rem;
        height: 30rem;
        img {
          position: absolute;
          width: 30rem;
          top: 15%;
          left: 10%;
        }
      }
      padding: 2.425rem;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px){
    
    .tab-content {
      .tab-left-content{
        img{
          width: 60%;
        }
      }
      .tab-right-content{
        .image-container {
          width: 20rem;
          height: 20rem;
          margin: auto;
          margin-bottom: 5rem;
          img {
            width: 20rem;   
          }
        }
        p:last-child {
          text-align: left;
        }
      }
    }
  }

  @media (max-width: 767px){   
    .tab-content {
      grid-template-columns: 1fr;
      .tab-left-content{
        img{
          width: 50%;
          margin-bottom: 1rem;
        }
      }
      .tab-right-content{
        justify-self: center;
        .image-container {
          width: 17rem;
          height: 17rem;
          img {
            width: 15rem;   
          }
        }
      }
    }
  }

`;