import axios from 'axios';
import { MAP_DIRECTIONS_REQUEST, MAP_DIRECTIONS_SUCCESSS, MAP_DIRECTIONS_FAILED } from '../constants/mapConstants';

const getDirections = (userLatitude, userLongitude, travelMode) => async (dispatch) => {
  dispatch({ type: MAP_DIRECTIONS_REQUEST, payload: { userLatitude, userLongitude, travelMode } });
  try {
    const { data } = await axios.get("/api/maps/directions", {
        params: {
          userLatitude, userLongitude, travelMode
        }
      });
    dispatch({ type: MAP_DIRECTIONS_SUCCESSS, payload: data });
  } catch (error) {
    dispatch({type: MAP_DIRECTIONS_FAILED, payload: error.message});
  }
}

export {getDirections};