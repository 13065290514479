import React from "react";
import Navbar from "../components/Navbar/Navbar";
import ContactUsForm from "../components/ContactUsForm/ContactUsForm";
import Footer from "../components/Footer/Footer";

export default function AboutUsPage() {
  const links = [
    {
      name: "Home",
      to: "/",
      active: false,
    },
    {
      name: "About Us",
      to: "/about-us",
      active: false,
    },
    {
      name: "Contact Us",
      to: "/contact-us",
      active: true,
    },
  ];

  return (
    <>
      <Navbar navLinks={links} />
      <div className="contact-us-layout">
        <main className="container">
          <ContactUsForm />
        </main>
        <Footer />
      </div>
    </>
  );
}
