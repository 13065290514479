import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import "./_Hamburger.scss";
import "../../App.scss";
import { Phone } from "../ProjectIcons";

export default function Navbar({ navLinks }) {
  const [isOpen, setIsOpen] = useState(false); //Navbar is open state

  useEffect(() => {
    const resizeListener = () => {
      setIsOpen(false);
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const navigationLinks = (
    <ul
      style={
        isOpen
          ? { gridTemplateRows: `repeat(${navLinks.length + 2},1fr)` }
          : { gridTemplateColumns: `repeat(${navLinks.length + 2},1fr)` }
      }
    >
      {navLinks.map((link, index) => (
        <li key={index}>
          <Link className={link.active ? "active" : ""} to={link.to}>
            {link.name}
          </Link>
        </li>
      ))}
      <li key={navLinks.length + 1}>
        <a href="https://vet.digitail.io/clinics/clairfields-mobile-veterinary-clinic">
          Book An Appointment
        </a>
      </li>
      <li key={navLinks.length + 2}>
        <a href="tel:+15197226314">
          <div className="phone-icon">
            <Phone />
          </div>
          Call Us
        </a>
      </li>
    </ul>
  );

  return (
    <NavbarComponent>
      <nav>
        <div className="nav-container">
          <div className="nav__brand">
            <Link to="/">
              <span className="logo-desktop">
                Clairfields Mobile Veterinary Clinic
              </span>
              <span className="logo-mobile">CMVC</span>
            </Link>
          </div>

          <button
            className={`nav__btn hamburger hamburger--collapse ${
              isOpen ? "is-active" : ""
            }`}
            type="button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
          <div className="nav__links">{navigationLinks}</div>
        </div>
      </nav>
      <div className="nav__spacer" />
      {
        /* If Mobile toggle is clicked show mobile/tablet menu */
        isOpen && <div className="nav-mobile-links">{navigationLinks}</div>
      }
      {isOpen && (
        <div
          className="nav-mobile-links__spacer"
          style={{ height: "200px", marginBottom: "2rem" }}
        />
      )}
    </NavbarComponent>
  );
}

const NavbarComponent = styled.div`  
  .nav-mobile-links {
    display: none;
    max-width: 1440px;
    margin: 1rem;
    position: absolute;
    width: 100%;
    height: 200px;
    background-color: rgba(255,255,255,0.5);
    > ul{
      padding: 0;
      list-style-type: none;
      display: grid;
      justify-content: center;
      grid-template-colums: 1fr;
      gap: 1.35rem;
      height: 100%;
      li{
        display: flex;       
        > * {
          display: inline-block;
          color: ${(props) => props.theme.main}; /* Night Blue Color */
          font-size: 1.5rem;
          text-decoration: none;
          position relative;
            &.active {
            color: #002929;
          }
        }
        &:last-child {
          a > .phone-icon {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  .nav__spacer {
    height: 13rem;
  }

  nav {
    display: flex;
    position: fixed;
    width: 100%;
    height: 13rem;
    background-color: rgba(255,255,255,0.5);
    z-index: 10;

    .nav-container {
      width: 100%;
      align-items: center;
      max-width: 1440px;
      grid-column: 1/-1;
      display: grid;
      grid-template-columns: min-content minmax(min-content, 1fr) 2fr;
      margin: 0 auto;

      .nav__btn {
        justify-self: end;
        display: none;
        margin-right: 1.75rem;   
      }

      .nav__brand {
        display: flex;
        font-size: 1.65rem;
        margin: 2rem 2rem;
        border: 1px solid ${(props) => props.theme.main};
        padding: 1rem;
        line-height: normal;
        a{
          color: ${(props) => props.theme.main};;
          text-decoration: none;
          text-transform: capitalize;
          .logo-mobile{
            display: none;
          }
        }
      }
      .nav__links {
        margin-right: 2rem;
        grid-column: 3 / span 1;
        font-size: 1.75rem;
        ul{
          list-style-type: none;
          display: grid;
          grid-template-rows: 1fr;
          gap: 5rem;
          li{
            a{
              position: relative;
              color: ${(props) => props.theme.main};
              font-weight: 600;
              width: max-content;
              &::after{
                content: "";
                width: 0;
                left: 50%;
                height: 3px;
                background-color: rgba(151,142,125,1.0);
                position: absolute;
                bottom: -1rem;
                transition: width .2s cubic-bezier(.215,.61,.355,1),left .2s cubic-bezier(.215,.61,.355,1);
              }
              &:not(.active):hover::after{
                width: 100%;
                left: 0;
              }
              &.active {
                color: #002929;
              }
            }
            a:not(.active):hover{
              transition: color .2s ease-in;
              color: rgba(151,142,125,1.0);
            }
          }
        }
      }
    }
  }

  /* Ipad & Mobile */
  @media (max-width: 1024px){
    .nav-mobile-links {
      display: block;
    }
    nav {
      height: 9rem;
      position: absolute;
      .nav-container {
        margin: -1px 1.25rem;
        .nav__btn {
          display: block !important;
          grid-column: 3 / span 1;
        }
        .nav__links {
          display: none;
        }
      }
    }
  }

  .nav__links , .nav-mobile-links {
    ul > li > a:last-child {
      display: flex;
    }
  }

  /* Mobile Only */
  @media (max-width: 768px){
    .nav__spacer {
      height: 9rem;
    }

    nav{
      height: 9rem;
      top: 0;
      .nav-container {
        .nav__brand {
          a{
            .logo-desktop {
              display: none;
            }
            .logo-mobile {
              display: block;
            }
          }
        }
      }
      .nav__btn {
        margin-right: 0 !important;
      }
    }
  }
`;

NavbarComponent.defaultProps = {
  theme: {
    main: "#465A3B",
  },
};
