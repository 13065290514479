import React, { useEffect } from "react";
import Glider, { GliderMethods } from "react-glider";
import styled from "styled-components";
import { generateMedia } from "styled-media-query";
import img1 from "../../assets/images/aboutus1.jpg";
import img2 from "../../assets/images/aboutus2.jpg";
import img3 from "../../assets/images/aboutus3.jpg";

export default function Carousel() {
  useEffect(() => {}, []);

  const gliderRef = React.useRef(GliderMethods);

  return (
    <CarouselContainer>
      <Glider
        draggable={true}
        slidesToShow={1}
        slidesToScroll={1}
        ref={gliderRef}
        hasArrows
        arrows={{
          prev: ".glider-prev",
          next: ".glider-next",
        }}
        dots="#dots"
      >
        <div className="slide-content">
          <div className="box" />
          <div className="text-overlay">
            <h1>
              <div>
                <span>Meet</span>
              </div>
              Dr.&nbsp;Nas
            </h1>
          </div>
          <img src={img1} alt="Dr.Nas during check-up" />
        </div>
        <div className="slide-content">
          <div className="box" />
          <div className="text-overlay">
            <h1>
              <div>
                <span id="dvm">Over</span>
              </div>
              20+ years of dedicated experience
            </h1>
          </div>
          <img src={img2} alt="Dr.Nas Mohamed DVM" />
        </div>
        <div className="slide-content">
          <div className="box" />
          <img src={img3} alt="Dr.Nas Mohamed Holding his puppy" />
        </div>
      </Glider>
      <button aria-label="Previous" className="glider-prev">
        «
      </button>
      <button aria-label="Next" className="glider-next">
        »
      </button>
    </CarouselContainer>
  );
}
const customMedia = generateMedia({
  xsPhone: "500px",
  phone: "760px",
  tablet: "900px",
  desktop: "1200px",
});

const CarouselContainer = styled.div`
  position: relative;
  margin: 0;

  .box {
    position: absolute;
    height: 100%;
    width: 20px;
    background-color: #faeda2;
  }

  .glider-prev,
  .glider-next {
    color: #465a3b;
    top: 40%;
    &:hover {
      color: #002929;
    }
    font-size: 6rem;
  }

  ${customMedia.greaterThan("xsPhone")`
  .box{
    width: 30px;
  }
  .glider-prev,
  .glider-next {
    font-size: 10rem;
  }
  `}

  ${customMedia.greaterThan("phone")`
  .box{
    width: 40px;
  }
  .glider-prev,
  .glider-next {
    font-size: 12rem;
  }
  `}

  ${customMedia.greaterThan("tablet")`
  .box{
    width: 50px;
  }
  .glider-prev,
  .glider-next {
    font-size: 16rem;
  }
  `}
  
  .glider-prev {
    left: 3%;
  }
  .glider-next {
    right: 3%;
  }
  .slide-content {
    max-width: 1440px;
    width: auto;
    background-color: #f0f0a2;
    position: relative;
    .text-overlay {
      position: absolute;
      top: 10%;
      left: 10%;
      color: #f0f0a2;
      h1 {
        position: relative;
        font-size: 2rem;
        font-weight: 700;
        line-height: 3rem;
        word-wrap: break-word;
        z-index: 100;
        div {
          transform: translate(-5%, 10%);
        }
        span {
          color: #fff;
          font-size: 1.5em;
          font-weight: 700;
          #dvm {
          }
        }
      }
      ${customMedia.greaterThan("xsPhone")`{
        h1{
          font-size: 3rem;
          font-weight: 700;
          line-height: 4rem;        
        }
      `}
      ${customMedia.greaterThan("phone")`{
        h1{
          font-size: 4rem;
          font-weight: 700;
          line-height: 5rem;        
        }
      `}

      ${customMedia.greaterThan("tablet")`
      h1{
        font-size: 6rem;
        font-weight: 700;
        line-height: 7rem;        
      }
      `}
    }
    img {
      object-fit: fill;
    }
  }
`;
