import React from 'react'
import './_HomeBanner.scss';

export default function HomeBanner({ title, subtitle, children }) {
  return (
    <div className="banner-wrapper">
      <div className="banner-top">
        <h1>{title}</h1>
      </div>
      <div className="banner-middle">
        <p>{subtitle}</p>
      </div>
      <div className="banner-bottom">
        {children}
      </div>

    </div>
  )
}


