import axios from 'axios';
import { EMAIL_CONTACT_INFO_REQUEST, EMAIL_CONTACT_INFO_SUCCESS, EMAIL_CONTACT_INFO_FAILED , ADD_TO_NEWSLETTER_REQUEST, ADD_TO_NEWSLETTER_SUCCESS, ADD_TO_NEWSLETTER_FAILED } from '../constants/emailConstants';

const emailContactInfo = ({firstName, lastName, Preference, email, phoneNumber, address, description}) => async(dispatch) => {
  dispatch({type: EMAIL_CONTACT_INFO_REQUEST, payload:{firstName, lastName, Preference, email, phoneNumber, address, description}});
  try {
    const {data} = await axios.post("api/email/ContactInfo", {firstName, lastName, Preference, email, phoneNumber, address, description});
    dispatch({ type: EMAIL_CONTACT_INFO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: EMAIL_CONTACT_INFO_FAILED, payload: error.message });
  }
}

const addToNewsletter = (email) => async(dispatch) => {
  dispatch({type: ADD_TO_NEWSLETTER_REQUEST, payload: { email } });
  try{
    const { data } = await axios.post("api/email/addToNewsletter", { email });
    dispatch({type: ADD_TO_NEWSLETTER_SUCCESS, payload: {data}});
  } catch (error) {
    dispatch({type: ADD_TO_NEWSLETTER_FAILED, payload: error.message});
  }
}

export {emailContactInfo, addToNewsletter};