import React, { Component } from 'react';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TabbedServicesPanelOne from './TabbedServicesPanelOne';
import TabbedServicesPanelTwo from './TabbedServicesPanelTwo';
import TabbedServicesPanelThree from './TabbedServicesPanelThree';
import { PriceTag1, Location, Clipboard } from '../ProjectIcons';

export default class TabbedServices extends Component {
  state = {
    tabIndex: 1,
  };

  render() {
    return (
      <TabbedServicesComponent id="aboutUs">
        <Tabs
          className="tabs"
          selectedIndex={this.state.tabIndex}
          onSelect={(tabIndex) => this.setState({ tabIndex })}
        >
          <TabList className="tab-nav-container">
            <Tab className={this.state.tabIndex === 0 ? 'active' : ''}>
              <div className="tab-content">
                <Clipboard />
                <h3>
                  <strong>Accredited. Certified DVM's.</strong>
                </h3>
              </div>
            </Tab>
            <Tab className={this.state.tabIndex === 1 ? 'active' : ''}>
              <div className="tab-content">
                <PriceTag1 />
                <h3>
                  <strong>Competitive Prices. Premium Service.</strong>
                </h3>
              </div>
            </Tab>
            <Tab className={this.state.tabIndex === 2 ? 'active' : ''}>
              <div className="tab-content">
                <Location />
                <h3>
                  <strong>Local</strong>
                </h3>
              </div>
            </Tab>
          </TabList>
          <TabPanel>
            <TabbedServicesPanelTwo />
          </TabPanel>
          <TabPanel>
            <TabbedServicesPanelOne />
          </TabPanel>
          <TabPanel>
            <TabbedServicesPanelThree />
          </TabPanel>
        </Tabs>
      </TabbedServicesComponent>
    );
  }
}

const TabbedServicesComponent = styled.div`
  ul.tab-nav-container {
    background-color: rgba(244, 244, 244, 1);
    list-style-type: none;
    margin: 0;
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #465a3b;
    box-shadow: 0px 3px 3px grey;

    li:not(.active):hover {
      color: #978e7d;
      cursor: pointer;
    }

    li {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .tab-content {
        padding: 1rem;
      }
      &.active {
        background-color: #f0f0a2;
        color: rgba(151, 142, 125, 1);
      }
      h3 {
        padding: 0;
        margin: 0;
      }
    }
  }
`;
