import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';
import { useSelector, useDispatch } from 'react-redux';
import { emailContactInfo } from '../../actions/emailActions';

export default function ContactUsForm() {
  // For math based security
  const [x1] = useState(Math.floor(Math.random() * 10));
  const [x2] = useState(Math.floor(Math.random() * 10));
  const { register, handleSubmit, errors } = useForm();
  const [prefferedMethodOfContact, setPrefferedMethodOfContact] =
    useState('preferPhone');
  const contactInfo = useSelector((state) => state.contactInfo);
  const { loading, info, error, success } = contactInfo;
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    data = {
      ...data,
      Preference: prefferedMethodOfContact,
    };
    dispatch(emailContactInfo(data));
  };

  return (
    <FormContainer>
      <h1 style={{ fontWeight: '700' }}>Contact Us</h1>
      {!success ? (
        <p>Enter your details down below and we will contact you back!</p>
      ) : (
        <div className='thank-you-wrapper'>
          <h1>
            Thank you {info.firstName} {info.lastName}
          </h1>
          <p>
            Your vet will contact you by{' '}
            {info.Preference === 'preferPhone' ? 'phone' : 'email'} shortly
          </p>
          <small>To send another email please refresh the page</small>
        </div>
      )}
      {!success && (
        <div className='form-container'>
          <p style={{ textAlign: 'center', fontSize: '10px' }}>
            required fields marked by *
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='form-section'>
              <label id='firstNameLabel' htmlFor='firstName'>
                First Name&nbsp;<span style={{ fontWeight: 'bold' }}>*</span>
              </label>
              <input
                id='firstName'
                type='text'
                name='firstName'
                style={errors.firstName ? { border: '1px solid red' } : {}}
                placeholder={errors.firstName ? 'Required' : ''}
                ref={register({ required: true })}
              />
            </div>

            <div className='form-section'>
              <label id='lastNameLabel' htmlFor='lastName'>
                Last Name&nbsp;<span style={{ fontWeight: 'bold' }}>*</span>
              </label>
              <input
                id='lastName'
                type='text'
                name='lastName'
                style={errors.lastName ? { border: '1px solid red' } : {}}
                placeholder={errors.lastName ? 'Required' : ''}
                ref={register({ required: true })}
              />
            </div>

            <div className='form-section'>
              <label id='emailLabel' htmlFor='email'>
                Email&nbsp;<span style={{ fontWeight: 'bold' }}>*</span>
              </label>
              <input
                id='email'
                type='text'
                name='email'
                style={errors.email ? { border: '1px solid red' } : {}}
                placeholder={errors.email && 'Invalid Email'}
                ref={register({
                  required: true,
                  pattern:
                    /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/i,
                })}
              />
            </div>

            <div className='form-section'>
              <label id='phoneNumberLabel' htmlFor='phoneNumber'>
                Phone Number&nbsp;<span style={{ fontWeight: 'bold' }}>*</span>
              </label>
              <input
                id='phoneNumber'
                type='text'
                name='phoneNumber'
                ref={register({
                  required: true,
                  minLength: { value: 6, message: 'phone number is too short' },
                  maxLength: 12,
                })}
                style={errors.phoneNumber ? { border: '1px solid red' } : {}}
                placeholder={errors.email && 'Invalid Phone Number'}
              />
            </div>

            <div className='form-section'>
              <label id='addressLabel' htmlFor='address'>
                Address&nbsp;<span style={{ fontWeight: 'bold' }}>*</span>
              </label>
              <input
                id='address'
                type='text'
                name='address'
                ref={register({
                  required: true
                })}
                style={errors.address ? { border: '1px solid red' } : {}}
              />
            </div>

            <div className='form-section'>
              <div
                className='radio-group'
                role='radiogroup'
                aria-labelledby='pmoc'
              >
                <div id='pmoc'>
                  Preffered Method of Contact{' '}
                  <span style={{ fontWeight: 'bold' }}>*</span>
                </div>
                <input
                  name='Preference'
                  type='radio'
                  value='PreferPhone'
                  id='PreferPhone'
                  defaultChecked
                  onChange={(e) => setPrefferedMethodOfContact(e.target.value)}
                />
                <label htmlFor='PreferPhone'>Phone</label>
                <input
                  name='Preference'
                  type='radio'
                  value='PreferEmail'
                  id='PreferEmail'
                  onChange={(e) => setPrefferedMethodOfContact(e.target.value)}
                />
                <label htmlFor='PreferEmail'>Email</label>
              </div>
            </div>

            <div className='form-section'>
              <label htmlFor='description'>
                Your Message <span style={{ fontWeight: 'bold' }}>*</span>
              </label>
              <textarea
                id='description'
                name='description'
                rows='4'
                cols='30'
                style={errors.description ? { border: '1px solid red' } : {}}
                ref={register({
                  required: true,
                  minLength: {
                    value: 6,
                    message: 'Please be more descriptive',
                  },
                })}
                placeholder={errors.description && 'Description too short'}
              />
            </div>
            <div className='form-section'>
              <label htmlFor='mathQuesiton'>I am not a robot</label>
              <p>
                {x1} + {x2} =
              </p>
              <input
                id='mathQuesiton'
                type='text'
                name='mathQuesiton'
                ref={register({
                  required: true,
                  validate: (value) => parseInt(value) === x1 + x2,
                })}
                style={errors.mathQuesiton ? { border: '1px solid red' } : {}}
                placeholder={errors.mathQuesiton ? 'Wrong...' : ''}
              />
            </div>
            <button type='submit'>
              {loading ? (
                <div className='spinner-border text-dark'>
                  <span className='sr-only'>Loading...</span>
                </div>
              ) : (
                'Submit'
              )}
            </button>
          </form>
          {error && (
            <div className='submission-error'>Error Submitting: {error}</div>
          )}
        </div>
      )}
    </FormContainer>
  );
}

const customMedia = generateMedia({
  desktop: '1024px',
  tablet: '960px',
  phone: '767px',
  xsPhone: '500px',
});

const FormContainer = styled.div`
  margin: 0 15%;
  ${customMedia.lessThan('phone')`
    margin: 0;
  `}

  .form-container {
    background-color: rgba(151, 142, 125, 0.1);
    border: 1px solid #333;
    padding: 4rem;
    ${customMedia.lessThan('xsPhone')`
    padding: 2.5rem 0;
   `}
    form {
      display: grid;
      grid-template-columns: 1fr;
      gap: 4rem;

      .form-section {
        display: inline-flex;
        justify-content: space-between;
        margin: 0 25%;
        input {
          height: 28px;
          border: none;
        }
        textarea {
          border: none;
        }
        .radio-group {
          width: auto;
          > input {
            margin: 0 1rem;
          }
        }
      }

      ${customMedia.lessThan('desktop')`
        .form-section {
          margin: 0 15%;
        }
      `}

      ${customMedia.lessThan('xsPhone')`
        .form-section {
          margin: 0 5%;
        }
      `}

      ${customMedia.lessThan('tablet')`
        gap: 2rem;
        .form-section {
          justify-content: space-between;
          padding: 0;
          .radio-group {
            width: 100%;
          }
        }
      `}

      ${customMedia.lessThan('phone')`
        #firstNameLabel, #lastNameLabel, #emailLabel, #phoneNumberLabel, #address {
          display: block;
        }
        row-gap: 1rem;
      `}

      ${customMedia.lessThan('xsPhone')`
        .form-section {
          display: block;
          padding: 0;         
        }
      `}


      button {
        grid-column: 1/-1;
        margin: auto;
        padding: 1.25rem 2.75rem;
        background-color: rgba(70, 90, 59, 0.75);
        color: #f3f3f3;
        border-radius: 0.5rem;
        transition: background-color 0.125s ease-out;
        border: 1px solid #f3f3f3;
        &:focus {
          outline: none;
        }
        &:hover {
          background-color: #f3f3f3;
          color: #465a3b;
          border: 1px solid #465a3b;
        }
      }
    }
  }

  .submission-error {
    display: block;
    color: red;
    text-align: center;
  }
`;
