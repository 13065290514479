import React from 'react';
import styled from 'styled-components';
import img from '../../assets/images/dog_out_window.jpg'

export default function Hero({children}) {
  return (
    <HomeHero className="hero">
      {children}
    </HomeHero>
  )
}


const HomeHero = styled.header`
  height: 100%;
  width: 100%;
  background: radial-gradient(rgba(255,255,2555,0), rgba(255,255,255,0.4)),
  url(${img}) center/cover;
  background-repeat: no-repeat;
  background-position: 25% 25%;
  padding: 1rem;
  text-align: center;


  @media (max-width: 767px){
    text-align: left;
    padding: 0.5rem 1rem;
  }
`;