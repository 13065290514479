import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withBaseIcon, Icon } from 'react-icons-kit';
import { priceTags } from 'react-icons-kit/icomoon/priceTags';
import { checkmark } from 'react-icons-kit/icomoon/checkmark';
import { cross } from 'react-icons-kit/icomoon/cross';
import { location2 } from 'react-icons-kit/icomoon/location2';
import { clipboard } from 'react-icons-kit/icomoon/clipboard';
import { facebook } from 'react-icons-kit/icomoon/facebook';
import { twitter } from 'react-icons-kit/icomoon/twitter';
import { phone } from 'react-icons-kit/icomoon/phone';
import { ic_error } from 'react-icons-kit/md/ic_error';
import { ic_directions } from 'react-icons-kit/md/ic_directions';
import { faSyringe } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faMicrochip } from '@fortawesome/free-solid-svg-icons';
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { faVials } from '@fortawesome/free-solid-svg-icons';
import { faTooth } from '@fortawesome/free-solid-svg-icons';

//lets say the icons on your side navigation are all color red
const TabIconContainer = withBaseIcon({ size: 30 });

const TableIconContainer = withBaseIcon({ size: 15 });

export const PriceTag1 = () => <TabIconContainer icon={priceTags} />;
export const Location = () => <TabIconContainer icon={location2} />;
export const Clipboard = () => <TabIconContainer icon={clipboard} />;
export const Facebook = () => <TabIconContainer icon={facebook} />;
export const Twitter = () => <TabIconContainer icon={twitter} />;
export const Phone = () => <Icon icon={phone} size={25} />;
export const Checkmark = () => <TableIconContainer icon={checkmark} />;
export const Cross = () => <TableIconContainer icon={cross} />;
export const Error = () => (
  <Icon icon={ic_error} size={20} style={{ margin: '0 0.25rem 0.25rem' }} />
);
export const Directions = () => <Icon icon={ic_directions} size={30} />;
export const Syringe = () => <FontAwesomeIcon icon={faSyringe} />;
export const Search = () => <FontAwesomeIcon icon={faSearch} />;
export const Microchip = () => <FontAwesomeIcon icon={faMicrochip} />;
export const Heartbeat = () => <FontAwesomeIcon icon={faHeartbeat} />;
export const Insect = () => <FontAwesomeIcon icon={faBug} />;
export const HorizontalEllipses = () => <FontAwesomeIcon icon={faEllipsisH} />;
export const Vials = () => <FontAwesomeIcon icon={faVials} />;
export const Tooth = () => <FontAwesomeIcon icon={faTooth} />;
