import React from "react";
import Hero from "../components/Hero/Hero";
import Banner from "../components/HomeBanner/HomeBanner";
import ButtonLink from "../components/ButtonLink/ButtonLink";
import Navbar from "../components/Navbar/Navbar";
import TabbedServices from "../components/TabbedServices/TabbedServices";
import Footer from "../components/Footer/Footer";
import Review from "../components/LeaveAReview/Review";

export default function HomePage() {
  const links = [
    {
      name: "Home",
      to: "/",
      active: true,
    },
    {
      name: "About Us",
      to: "/about-us",
      active: false,
    },
    {
      name: "Contact Us",
      to: "/contact-us",
      active: false,
    },
  ];

  return (
    <>
      <Navbar navLinks={links} />
      <div className="home-layout">
        <main className="container">
          <Hero>
            <Banner
              title="Your pet is in good hands!"
              subtitle="Our mission is to provide unparalleled compassionate service at competitive prices. We are deeply committed to prioritizing the health and well-being of our clients' beloved pets, with the utmost seriousness. Our team wholeheartedly dedicates to ensure every service we provide receives unwavering dedication and effort, striving for excellence in every aspect of our work."
            >
              <ButtonLink link="/about-us" callToAction="See Our Story" />
            </Banner>
          </Hero>
          <TabbedServices />
          <Review />
        </main>
        <Footer />
      </div>
    </>
  );
}
