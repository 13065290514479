import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function ButtonLink({ link, callToAction, theme, external }) {
  return (
    <StyledButton theme={theme}>
      <Link
        to={link}
        target={external ? '_blank' : '_self'}
        className="animated-arrow"
      >
        <span className="the-arrow -left">
          <span className="shaft" />
        </span>
        <span className="main">
          <span className="text">{callToAction}</span>
          <span className="the-arrow -right">
            <span className="shaft" />
          </span>
        </span>
      </Link>
    </StyledButton>
  );
}

const StyledButton = styled.div`
  display: inline-block;
  .the-arrow {
    width: ${(props) => props.shaft.width};
    transition: all 0.2s;

    &.-left {
      position: absolute;
      top: 60%;
      left: 0;

      > .shaft {
        width: 0;
        background-color: ${(props) => props.theme.main};

        &:before,
        &:after{
          width: 0;
          background-color: ${(props) => props.theme.main};
        }
        
        &:before {
          transform: rotate(0);
        }
  
        &:after {
          transform: rotate(0);
        }
      }
    }

    &.-right{
      top: 3px;

      > .shaft {
        width: ${(props) => props.shaft.width};
        transition-delay: 0.2s;

        &:before,
        &:after{
          width: ${(props) => props.arrowHead.width};
          transition-delay: 0.3s;
          transition: all 0.5s;
        }

        &:before {
          transform: rotate(40deg);
        }
        
        &:after {
          transform: rotate(-40deg);
        }
      }
    }
    
    > .shaft{
      background-color: ${(props) => props.theme.main};
      display: block;
      height: ${(props) => props.shaft.thickness};
      position: relative;
      transition: all 0.2s;
      transition-delay: 0;
      will-change: transform;

      &:before,
      &:after {
        background-color: ${(props) => props.theme.main};
        content: '';
        display: block;
        height: ${(props) => props.arrowHead.thickness};
        position: absolute;
        top: 0;
        right: 0;
        transition: all 0.2s;
        transition-delay: 0;
      }
      
      &:before {
        transform-origin: top right;
      }
  
      &:after {
        transform-origin: bottom right;
      }
    }
  }


  /* Animated Arrow Button */
  .animated-arrow {
    display: inline-block;
    color: ${(props) => props.theme.main};
    font-size: ${(props) => props.fontSize};
    font-style: italic;
    text-decoration: none;
    position: relative;
    transition: all 0.2s;

    &:hover {
      color: ${(props) => props.theme.hover};

      > .the-arrow.-left {
        > .shaft {
          width: ${(props) => props.newShaftWidth};
          transition-delay: 0.1s;
          background-color: ${(props) => props.theme.hover};

          &:before,
          &:after {
            width: ${(props) => props.arrowHead.width};
            transition-delay: 0.1s;
            background-color: ${(props) => props.theme.hover};
          }

          &:before {
            transform: rotate(40deg);
          }
          
          &:after {
            transform: rotate(-40deg);
          }
        }
      }
      > .main {
        transform: translateX(${(props) => {
          return (
            parseInt(
              props.shaft.width.slice(0, props.shaft.width.indexOf('px'))
            ) +
            parseInt(
              props.textArrowSpace.slice(0, props.textArrowSpace.indexOf('px'))
            )
          );
        }}px);
        transform: translateX(${(props) => {
          return (
            parseInt(
              props.newShaftWidth.slice(0, props.newShaftWidth.indexOf('px'))
            ) +
            parseInt(
              props.textArrowSpace.slice(0, props.textArrowSpace.indexOf('px'))
            )
          );
        }}px);
      
        > .the-arrow.-right {
          > .shaft {
            width: 0;
            transform: translateX(200%);
            transition-delay: 0;

            &:before,
            &:after {
              width: 0;
              transition-delay: 0;
              transition: all 0.1s;
            }
  
            &:before {
              transform: rotate(0);
            }
  
            &:after {
              transform: rotate(0);
            }
          }
        }
      }
    }

    > .main {
      display: flex;
      align-items: center;
      transition: all 0.2s;

      > .text {
        margin: 0 ${(props) => props.textArrowSpace} 0 0;
        line-height: 1;
      }

      > .the-arrow {
        position: relative;
      }
    }
  }
=`;

StyledButton.defaultProps = {
  theme: {
    main: '#100000',
    hover: '#978E7D',
  },
  shaft: {
    width: '50px',
    thickness: '1px',
  },
  arrowHead: {
    width: '8px',
    thickness: '1px',
  },
  newShaftWidth: '50px',
  textArrowSpace: '16px',
  fontSize: '1.75em',
};
