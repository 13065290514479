import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import PhotoCarousel from "../components/Carousel/PhotoCarousel";
import TwoPartBanner from "../components/TwoPartBanner";
import Services from "../components/Services/Services";
import img1 from "../assets/images/CartoonVet.png";

export default function AboutUsPage() {
  const links = [
    {
      name: "Home",
      to: "/",
      active: false,
    },
    {
      name: "About Us",
      to: "/about-us",
      active: true,
    },
    {
      name: "Contact Us",
      to: "/contact-us",
      active: false,
    },
  ];

  return (
    <>
      <Navbar navLinks={links} />
      <div className="about-us-layout">
        <PhotoCarousel />
        <TwoPartBanner
          title="Introducing our practitioner"
          subtitles={[
            "At Clairfields Mobile Veterinary Clinic, we take pride in our exceptional team of professionals who are committed to providing the highest level of care for your beloved pets. Led by Dr. Nas, our experienced staff brings a wealth of knowledge and expertise in the field of small animal medicine. Dr. Nas is a highly skilled veterinarian with over 20 years of dedicated experience in the field. Throughout his career, he has successfully treated a wide range of small animal patients, gaining invaluable expertise in diagnosing and managing various health conditions. Dr. Nas is deeply passionate about animal welfare and goes above and beyond to ensure the well-being of every patient he encounters.",
            "When you bring your pet to Clairfields Mobile Veterinary Clinic, you can trust that they will be in caring and capable hands. Our staff is dedicated to providing personalized attention to every patient, tailoring treatment plans to meet their unique needs. We understand that pets are cherished family members, and we treat them with the utmost care and respect.",
            "We continuously stay updated with the latest advancements in veterinary medicine, attending conferences and engaging in ongoing professional development. This allows us to offer the most up-to-date and effective treatments for your furry companions.",
            "At Clairfields Mobile Veterinary Clinic, our staff is not only passionate about providing exceptional medical care but also about building lasting relationships with our clients. We strive to ensure that you and your pet feel heard, supported, and well-informed throughout every step of the veterinary care journey.",
            "We look forward to welcoming you and your furry friend to our clinic and providing them with the highest quality care they deserve. Your pet's well-being is our top priority, and we are here to support you every step of the way.",
          ]}
          alignment="left"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={img1}
              style={{ maxHeight: "400px" }}
              alt="drawn vetrinarian"
            ></img>
          </div>
        </TwoPartBanner>
        <Services />
        <Footer />
      </div>
    </>
  );
}
