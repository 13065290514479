import React, { useState } from 'react';
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';
import { Facebook, Twitter, Error } from '../ProjectIcons';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addToNewsletter } from '../../actions/emailActions';

export default function Footer() {
  const [newsLetterEmail, setNewsLetterEmail] = useState('');
  const [newsLetterError, setNewsLetterError] = useState(false);
  const [newsLetterErrorMessage, setNewsLetterErrorMessage] = useState('');
  const regExp = RegExp(
    /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
  );
  const { loading, error, success } = useSelector(
    (state) => state.newsLetterInfo
  );
  const dispatch = useDispatch();

  const validate = () => {
    setNewsLetterError(false);
    if (newsLetterEmail.length === 0) {
      setNewsLetterError(true);
      setNewsLetterErrorMessage('Please enter an email address');
      return false;
    } else if (!newsLetterEmail.match(regExp)) {
      setNewsLetterError(true);
      setNewsLetterErrorMessage('Your email address must be valid');
      return false;
    }
    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const result = validate();
    if (result) {
      dispatch(addToNewsletter(newsLetterEmail));
    }
  };

  return (
    <FooterContainer>
      <div className='footer-container'>
        <div className='footer-grid-container hours-of-operation'>
          <h3>Hours of Operation</h3>
          <div className='hours-container'>
            <span className='left'>Monday</span>
            <span className='right'>10:00 a.m - 8 p.m</span>
          </div>
          <div className='hours-container'>
            <span className='left'>Tuesday</span>
            <span className='right'>10:00 a.m - 8 p.m</span>
          </div>
          <div className='hours-container'>
            <span className='left'>Wednesday</span>
            <span className='right'>10:00 a.m - 2 p.m</span>
          </div>
          <div className='hours-container'>
            <span className='left'>Thursday</span>
            <span className='right'>10:00 a.m - 8 p.m</span>
          </div>
          <div className='hours-container'>
            <span className='left'>Friday</span>
            <span className='right'>10:00 a.m - 8 p.m</span>
          </div>
          <div className='hours-container'>
            <span className='left'>Saturday</span>
            <span className='right'>10:00 a.m - 8 p.m</span>
          </div>
          <div className='hours-container'>
            <span className='left'>Sunday</span>
            <span className='right'>10:00 a.m - 2 p.m</span>
          </div>
        </div>
        <div className='footer-grid-container connect-with-us'>
          <h3>Connect With Us:</h3>
          <div className='social-media-icons'>
            <div className='facebook-icon'>
              <Link to='/'>
                <Facebook />
              </Link>
            </div>
            <div className='twitter-icon'>
              <Link to='/'>
                <Twitter />
              </Link>
            </div>
          </div>
        </div>
        <div className='footer-grid-container'>
          <div className='newsletter'>
            <h3>Follow our Newsletter</h3>
            <form action='post'>
              {!success ? (
                <div className='form-container'>
                  <input
                    type='text'
                    onChange={(e) => setNewsLetterEmail(e.target.value)}
                  />
                  <label htmlFor=''>Email</label>
                  <button
                    className='btn btn-default newsletter-subscribe'
                    onClick={(e) => onSubmit(e)}
                  >
                    {loading ? (
                      <div className='spinner-border text-dark'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                    ) : (
                      'Subscribe'
                    )}
                  </button>
                </div>
              ) : (
                <div className='success-container'>Thanks for subscribing</div>
              )}
              <div
                style={
                  newsLetterError ? { display: 'block' } : { display: 'none' }
                }
                className='alert alert-danger'
                role='alert'
              >
                <Error />
                <span className='sr-only'>Error:</span>
                {newsLetterErrorMessage}
              </div>
              {error && (
                <div className='alert alert-danger' role='alert'>
                  <Error />
                  <span className='sr-only'>Error:</span>
                  {error}
                </div>
              )}
            </form>
          </div>
          <div className='contact-info'>
            <p> Dr. Nas </p>
            <a href='tel:+15197226314'>519-722-6314</a>
            <br /> 
            <a href='mailto:clairfieldsvet@gmail.com'>clairfieldsvet@gmail.com</a>
          </div>
          <div className='address-info'>
            <p className='text-nowrap'>152 Farley Drive, Guelph, Ontario N1L 1M1</p>
          </div>
        </div>
      </div>
    </FooterContainer>
  );
}

const customMedia = generateMedia({
  tablet: '960px',
  phone: '767px',
});

const FooterContainer = styled.footer`
  max-width: 1440px;
  width: 100%;
  height: 100%;
  background-color: rgba(244, 244, 244, 1);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
  }

  .footer-container {
    margin: 0 15%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5rem;
    ${customMedia.lessThan('tablet')`
      grid-template-columns: repeat(2,1fr);
      margin: 0 10%;
      column-gap: 10rem;    
    `}

    ${customMedia.lessThan('phone')`
      grid-template-columns: 1fr;
      margin: 0 5%;
      gap: 0;
    `}

    .footer-grid-container {
      margin: 2.75rem 0;
    }

    .hours-of-operation {
      ${customMedia.greaterThan('tablet')`
        grid-column: 1/span 2;
      `}
    }

    .connect-with-us {
      ${customMedia.greaterThan('tablet')`
        grid-column: 3/span 1;
      `}
    }

    .hours-container {
      padding: 1rem 0;
      .left {
        font-weight: 700;
      }
      .right {
        float: right;
      }
    }

    .social-media-icons {
      display: flex;
      > * {
        padding: 1.735rem;
      }
      .facebook-icon svg {
        fill: #3b5998;
      }

      .twitter-icon svg {
        fill: #08a0e9;
      }
    }

    .newsletter {
      .alert {
        margin-top: 1rem;
      }
      .form-container {
        width: 100%;
        display: inline-flex;
        position: relative;
        align-items: center;
        label {
          position: absolute;
          left: 5px;
          top: 9px;
          color: #888;
          font-size: 1.5rem;
          transition: transform 150ms ease-out, font-size 150ms ease-out;
        }
        input {
          height: 48px;
          border-radius: 0.25rem;
          background-color: transparent;
          border: 1px solid #888;
          padding: 1rem 1rem 0;
          z-index: 10;
        }
        .btn.newsletter-subscribe {
          font-size: 1.3rem;
          background-color: rgba(70, 90, 59, 0.75);
          padding: 1.3rem;
          margin: 0.1rem 0.5rem;
          color: #fff;
        }

        input:focus ~ label {
          font-size: 1rem;
          top: 0.4375rem;
        }
        input:focus {
          outline: none;
        }
      }
    }
  }

  .contact-info {
    margin: 3rem 0 2rem 0;
    p:first-child {
      margin: 0;
    }
    a {
      color: #465a3b;
    }
  }
  .address-info {
    p {
      margin: 0;
    }
  }
`;
