import React from 'react'
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';
import img from '../../assets/images/google-reviews.png';

export default function Review() {
    return (
        <ReviewComponent>
            <img src={img} alt="Google Reviews Logo"/>
            <h2>We would really appreciate your feedback!</h2>
            <div className="button-container">
                <a href="https://search.google.com/local/writereview?placeid=ChIJ7TUR-ViFK4gR7JqGAlUAazA" target="_blank" rel="noopener noreferrer">Leave a review</a>
            </div>
            <div className="vignette"></div>
        </ReviewComponent>
    )
}


const customMedia = generateMedia({
    desktop: '1024px',
    tablet: '960px',
    phone: '767px',
    xsPhone: '450px'
});  

const ReviewComponent = styled.div` 
    ${customMedia.greaterThan('tablet')`
        height: 250px;
    `}
    background-color: white;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    position: relative;
    h2{
        text-align: center;
        line-height: 3.25rem;
    }
    img{
        display: block;
        height: 100px;
        width: 150px;
        z-index: 10;
    }
    .button-container{
        display: flex;
        margin: 10px;
        ${customMedia.lessThan('xsPhone')`
            flex-direction: column;
            margin: 10px;
        `}
        a{
            z-index: 10;
            font: 1.375rem Arial;
            margin: auto;
            padding: 1.25rem 2.75rem;
            background-color: rgba(70,90,59,0.75);
            color: #f3f3f3;
            border-radius: 0.5rem;
            transition: background-color .125s ease-out;
            border: 1px solid #f3f3f3;
            &:focus {
                outline: none;
            }
            &:hover{
                cursor: pointer;
                background-color: #f3f3f3;
                color: #465A3B;
                border: 1px solid #465A3B;
            }
        }
    }
    .vignette {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 120px rgba(0,0,0,0.075) inset;
        
    }
`;