import { EMAIL_CONTACT_INFO_REQUEST, EMAIL_CONTACT_INFO_SUCCESS, EMAIL_CONTACT_INFO_FAILED, ADD_TO_NEWSLETTER_REQUEST, ADD_TO_NEWSLETTER_SUCCESS, ADD_TO_NEWSLETTER_FAILED} from '../constants/emailConstants';

function contactInfoReducer(state = {}, action) {
  switch(action.type) {
    case EMAIL_CONTACT_INFO_REQUEST:
      return { loading: true };
    case EMAIL_CONTACT_INFO_SUCCESS:
      return { loading: false, success: true, info: action.payload };
    case EMAIL_CONTACT_INFO_FAILED:
      return { loading: false, success: false, error: action.payload };
    default: return state;
    }
}

function addToNewsletterReducer(state = {}, action) {
  switch(action.type) {
    case ADD_TO_NEWSLETTER_REQUEST:
      return { loading: true }; 
    case ADD_TO_NEWSLETTER_SUCCESS:
      return { loading: false, success: true, data: action.payload};
    case ADD_TO_NEWSLETTER_FAILED:
       return { loading: false, success: false, error: action.payload };
    default: return state;
      
  }
}


export { contactInfoReducer, addToNewsletterReducer };