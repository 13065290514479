import React from 'react';
import styled from 'styled-components';
import ButtonLink from '../ButtonLink/ButtonLink';
import { Checkmark, Cross } from '../ProjectIcons';

export default function TabbedServicesPanelOne() {
  return (
    <TabPanelContainer>
      <div className="tab-content">
        <div className="tab-top-content">
          <h1> Better Service. Lower Prices. </h1>
          <ButtonLink
            className="btn"
            link={{
              pathname:
                'https://vet.digitail.io/clinics/clairfields-mobile-veterinary-clinic',
            }}
            callToAction="Book Now"
            theme={{ main: '#465A3B', hover: '#978E7D' }}
            external={true}
          />
        </div>
        <div className="tab-bottom-content">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Standard Veterinary Clinic</th>
                <th>Mobile Veterinary Clinic</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Low operation costs</td>
                <td>
                  <div className="cell-container">
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <Cross />
                    </span>
                    <p>
                      Expensive Overhead costs are factored into your service
                      fees
                    </p>
                  </div>
                </td>
                <td>
                  <div className="cell-container">
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <Checkmark />
                    </span>
                    <p>
                      <strong>
                        We save on traditional overhead costs that are
                        associated with a standard veterinary clinic. The
                        savings are factored into your price!
                      </strong>
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Less stress for patients</td>
                <td>
                  <div className="cell-container">
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <Cross />
                    </span>
                    <p>Frequent delays, long waits in crowded wiating areas</p>
                  </div>
                </td>
                <td>
                  <div className="cell-container">
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <Checkmark />
                    </span>
                    <p>
                      <strong>
                        No waiting in cramped waiting areas, no inconvenience,
                        no hassle!
                      </strong>
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Convenience - Flexibility</td>
                <td>
                  <div className="cell-container">
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <Cross />
                    </span>
                    <p>Inconvenient.</p>
                  </div>
                </td>
                <td>
                  <div className="cell-container">
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <Checkmark />
                    </span>
                    <p>
                      <strong>
                        We can come to you - Or you can come to us. Whatever
                        works for you.
                      </strong>
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </TabPanelContainer>
  );
}

const TabPanelContainer = styled.div`
  background-color: #f0f0a2;

  .tab-content {
    margin: 0 15%;
    padding-bottom: 2%;
    margin-top: 0.25rem;

    .tab-top-content {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      padding: 2.75rem 0 1rem;
      color: #465a3b;
      h1 {
        font-size: 3.5rem;
        grid-column: 1/8;
      }
      div:last-child {
        grid-column: 9/12;
        align-self: center;
      }
    }
    .tab-bottom-content {
      margin-top: 1rem;
      /* Table */
      table {
        width: 100%;
        border-collapse: collapse;
        tr {
          th:not(:last-child),
          td:not(:last-child) {
            border-right: 1px solid black;
          }
        }
        thead {
          tr {
            &:first-child {
              background-color: #faeda2;
            }
            text-align: center;
            th {
              text-transform: uppercase;
              font-weight: bold;
              padding: 0.8rem;
            }
          }
        }
        tbody {
          vertical-align: middle;
          display: table-row-group;
          tr {
            &:nth-child(odd) {
              border-top: 1px solid #222;
              &:not(:last-child) {
                border-bottom: 1px solid #222;
              }
            }
            &:nth-child(even) {
              background-color: #faeda2;
            }
            td {
              padding: 0.8rem 1.2rem;
              text-align: center;
              &:first-child {
                font-weight: bold;
                text-align: left;
                width: 20rem;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .tab-content {
      margin: 0;
      .tab-top-content {
        padding: 1.5rem;
        grid-template-columns: repeat(1, 1fr);
        h1 {
          font-size: 3rem;
          grid-column: 1/1;
        }
        div:last-child {
          grid-column: 1/1;
        }
      }
      .tab-bottom-content {
        table {
          tbody {
            tr {
              td {
                padding: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
`;
