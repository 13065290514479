import React from 'react';
import './_services.scss';
import {
  Syringe,
  Search,
  Microchip,
  Heartbeat,
  Insect,
  HorizontalEllipses,
  Vials,
  Tooth,
} from '../ProjectIcons';

export default function Services() {
  return (
    <section className="services-section">
      <div className="services-container">
        <div className="row animate-box fadeInUp animated-fast">
          <div className="col-md-8 offset-md-2 text-center services-heading">
            <h2>What We Offer</h2>
            <p>
              The medical, wellness, and vaccination services provided by
              Clairfields Mobile Veterinary Clinic
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div
              className="feature-left animate-box fadeInLeft animated-fast"
              data-animate-effect="fadeInLeft"
            >
              <span className="icon">
                <Syringe />
              </span>
              <div className="feature-copy">
                <h3>Vaccinations</h3>
                <p>Annual vaccinations for dogs and cats</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="feature-left animate-box fadeInLeft animated-fast"
              data-animate-effect="fadeInLeft"
            >
              <span className="icon">
                <Microchip />
              </span>
              <div className="feature-copy">
                <h3>Microchipping</h3>
                <p>Increase the chances of finding your lost pet</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="feature-left animate-box fadeInLeft animated-fast"
              data-animate-effect="fadeInLeft"
            >
              <span className="icon">
                <Search />
              </span>
              <div className="feature-copy">
                <h3>Exams</h3>
                <p>Examinations and consultations</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="feature-left animate-box fadeInLeft animated-fast"
              data-animate-effect="fadeInLeft"
            >
              <span className="icon">
                <Heartbeat />
              </span>
              <div className="feature-copy">
                <h3>Heartworm</h3>
                <p>Heartworm detection and prevention</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="feature-left animate-box fadeInLeft animated-fast"
              data-animate-effect="fadeInLeft"
            >
              <span className="icon">
                <Insect />
              </span>
              <div className="feature-copy">
                <h3>De-worming and parasite prevention</h3>
                <p>Check and treat internal and external parasites.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="feature-left animate-box fadeInLeft animated-fast"
              data-animate-effect="fadeInLeft"
            >
              <span className="icon">
                <Tooth />
              </span>
              <div className="feature-copy">
                <h3>Dog and cat dental health</h3>
                <p>
                  Check teeth for tartar buildup, gum inflammation, other dental
                  problems.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="feature-left animate-box fadeInLeft animated-fast"
              data-animate-effect="fadeInLeft"
            >
              <span className="icon">
                <Vials />
              </span>
              <div className="feature-copy">
                <h3>Biopsies</h3>
                <p>Diagnostic lab work</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="feature-left animate-box fadeInLeft animated-fast"
              data-animate-effect="fadeInLeft"
            >
              <span className="icon">
                <HorizontalEllipses />
              </span>
              <div className="feature-copy">
                <h3>Additional Services</h3>
                <p>counseling and more</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
