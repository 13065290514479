import { MAP_DIRECTIONS_REQUEST, MAP_DIRECTIONS_SUCCESSS, MAP_DIRECTIONS_FAILED } from '../constants/mapConstants';

function mapDirectionsReducer(state = {}, action) {
  switch (action.type) {
    case MAP_DIRECTIONS_REQUEST:
      return { loading: true };
    case MAP_DIRECTIONS_SUCCESSS:
      return { loading: false, success: true, data: action.payload };
    case MAP_DIRECTIONS_FAILED:
      return { loading: false, success: false, error: action.payload };
    default: return state;
  }
}

export { mapDirectionsReducer };