import React from 'react'
import styled from 'styled-components';
import MapComponent from '../MapComponent';

export default function TabbedServicesPanelThree() {

  return (
    <TabPanelContainer>
      <div className="tab-content">
        <div className="tab-top-content">
          <h1>We are localy owned business!</h1>
          <p>We are located in the beautiful south ends of the Guelph commons. We can take care of your pet here or we can come to your place!</p>
        </div>
      </div>
      <div className="map-container">
        <MapComponent />
      </div>
    </TabPanelContainer>
  )
}


const TabPanelContainer = styled.div`
  background-image:  linear-gradient(to right, #FAEDA2 0%, #FAEDA2 50%, #F0F0A2 50%);
  height: 62rem;  
  width: 100%;
  position: relative;
  .tab-content {
    margin: 0 15%;
    .tab-top-content {
      display: grid;
      grid-template-rows: min-content min-content;
      gap: 0.25rem;
      margin-top: 0.25rem;
      h1{
        font-size: 3rem;
        margin-top: 1.5rem;
      }
      p{
        grid-row: 2/span 1;
        margin: 0;
      }
    }
  }
  .map-container {
  }

  @media(max-width: 767px){
    height: 78rem;  
  }
`;