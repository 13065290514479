import React, { useState } from "react";
import { Map, GoogleApiWrapper, Marker, Polyline } from "google-maps-react";
import styled from "styled-components";
import { generateMedia } from "styled-media-query";
import { Directions } from "./ProjectIcons";
import { getDirections } from "../actions/mapActions";
import { useDispatch, useSelector } from "react-redux";

function MapComponent(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const { loading, error, data } = useSelector((state) => state.directionsData);

  const getGeoLocation = () => {
    // If Browser supports Geo Location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(
            getDirections(
              position.coords.latitude,
              position.coords.longitude,
              "DRIVING"
            )
          );
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setErrorMessage(
                "You have denied the request for Geolocation. cannot get directions..."
              );
              break;
            case error.POSITION_UNAVAILABLE:
              setErrorMessage("Location information is unavailable");
              break;
            case error.TIMEOUT:
              setErrorMessage("The request to get user location timed out");
              break;
            case error.UNKNOWN_ERROR:
              setErrorMessage(
                "The GeoService Location Failed for an unknown reason"
              );
              break;
            default:
              setErrorMessage(
                "The GeoService Location Failed for an unknown reason"
              );
          }
        }
      );
    } else {
      setErrorMessage("Your Browser Doesn't Support GeoLocation");
    }
  };

  const handleClick = () => {
    getGeoLocation();
  };

  return (
    <MapContainer>
      <Map
        google={props.google}
        zoom={
          data
            ? 16 -
              Math.ceil(
                Number(data.distance.substr(0, data.distance.indexOf(" "))) / 12
              )
            : 15
        }
        style={{
          height: "80%",
          width: "80%",
          left: "10%",
          top: "15%",
          maxHeight: "40rem",
        }}
        initialCenter={{ lat: 43.506504, lng: -80.192 }}
      >
        <Marker position={{ lat: 43.506504, lng: -80.192 }} />
        {data && (
          <Polyline
            path={data.path}
            strokeColor="#751534"
            strokeOpacity={0.8}
            strokeWeight={2}
          />
        )}
      </Map>
      <div className="button-container">
        {!data && (
          <button
            type="button"
            onClick={() => handleClick()}
            disabled={loading}
          >
            Get Directions&nbsp;
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Directions Loading...</span>
              </div>
            ) : (
              <Directions />
            )}
          </button>
        )}
        <br />
        {error ? (
          <p id="mapsErrorMessage">Error has occured: {error}</p>
        ) : data ? (
          <p id="mapsDistanceMessage">You are {data.distance} away</p>
        ) : (
          <p>*Geolocation estimate*</p>
        )}
        {errorMessage && (
          <p id="mapsErrorMessage">Error has occured: {errorMessage}</p>
        )}
      </div>
    </MapContainer>
  );
}

const customMedia = generateMedia({
  phone: "760px",
});

const MapContainer = styled.div`
  display: flex;
  .button-container {
    margin: 3rem auto;
    ${customMedia.greaterThan("phone")`    
      margin: 1rem auto;
    `}
    #mapsErrorMessage {
      text-align: center;
      color: red;
    }
    #mapsDistanceMessage {
      text-align: center;
      color: green;
    }
    z-index: 10;
  }
  button {
    border: 1px solid #465a3b;
    color: #465a3b;
    font-weight: 700;
    border-radius: 2.25rem;
    padding: 1rem;
  }
`;

const CustomLoading = (props) => (
  <LoadingContainer>
    <div
      className="d-flex spinner-border align-items-center justify-content-center"
      style={{ width: "15rem", height: "15rem" }}
    >
      <span className="sr-only">Map Loading...</span>
    </div>
  </LoadingContainer>
);

const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  height: 80%;
  width: 80%;
  color: #465a3b;
  left: 10%;
  top: 15%;
  justify-content: center;
  align-items: center;
`;

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  LoadingContainer: CustomLoading,
})(MapComponent);
