import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import HomePage from "./pages/HomePage";
import { Switch, Route } from "react-router-dom";
import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";

function App() {
  return (
    <>
      <Switch>
        <Route path="/contact-us" component={ContactUsPage} />
        <Route path="/about-us" component={AboutUsPage} />
        <Route path="/" exact={true} component={HomePage} />
      </Switch>
      <p style={{ textAlign: "center" }}>&copy; All rights reserved</p>
    </>
  );
}

export default App;
